import React from "react";
import "./Experience.scss";
import Input from "../../ui-component/Input/Input";
import InputDate from "../../ui-component/InputDate/InputDate";
import Remove from "../../assets/icons/x.svg";

export default function Education({
  index,
  onRemoveExperience,
  title,
  works,
  value,
}) {
  const [form, setForm] = React.useState({
    work: "",
    post: "",
    from: "",
    to: "",
  });

  React.useEffect(() => {
    if (works) {
      setForm({
        work: works.work ?? "",
        post: works.post ?? "",
        from: works.from ?? "",
        to: works.to ?? "",
      });
    }
  }, [works]);
  const [selectedOption, setSelectedOption] = React.useState("");
  const [startDate, setStartDate] = React.useState(new Date());
  const [startDate1, setStartDate1] = React.useState(new Date());

  const handleInputChange = (value, index) => {
    // onInputChange(fieldName, value);
    setForm({ ...form, [index]: value });
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  function handleClick(event) {
    event.preventDefault();
  }
  return (
    <div className="educations-container-block">
      <div className="collapse__header">
        <h4 className="collapse__title">{title}</h4>
        {index !== 0 && (
          <div>
            <button
              onClick={handleClick}
              style={{ border: " none", backgroundColor: "transparent" }}
            >
              <img
                className="images-remove"
                src={Remove}
                onClick={onRemoveExperience}
                alt="Удалить"
              />
            </button>
          </div>
        )}
      </div>
      <div className="Selected-app-block">
        <Input
          onChange={(e) => handleInputChange(e.target.value, "work")}
          value={form.work}
          className={"input2"}
          name={`works[${index}][work]`}
          title="Место работы"
          placeholder={"Место работы"}
        />
        <Input
          onChange={(e) => handleInputChange(e.target.value, "post")}
          value={form.post}
          className={"input2"}
          name={`works[${index}][post]`}
          title="Должность"
          placeholder={"Напишите должность"}
        />
      </div>
      <div className="datePickers-block1">
        <InputDate
          value={form.from}
          onChange={(e) => handleInputChange(e.target.value, "from")}
          title="Дата начала работы"
          name={`works[${index}][from]`}
        />
        <InputDate
          value={form.to}
          onChange={(e) => handleInputChange(e.target.value, "to")}
          title="Дата увольнения"
          name={`works[${index}][to]`}
        />
      </div>
    </div>
  );
}
