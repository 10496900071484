import React from "react";
import MultiSelected from "../../MultiSelected/MultiSelected";
import { SelectedClass } from "../../SelectedClass/SelectedClass";
import { FurtherComponent } from "../../Further/Further";
import Input from "../../Input/Input";

export default function FormTeacher({
  teachers,
  disciplines,
  setDisciplines,
  value,
}) {
  return (
    <div
      style={{
        marginTop: "12px",
        marginBottom: "12px",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <MultiSelected
        value={value}
        disciplines={disciplines}
        setDisciplines={setDisciplines}
      />
      
      <SelectedClass value={value} teachers={teachers} />
      <FurtherComponent value={value} teachers={teachers} />
    </div>
  );
}
