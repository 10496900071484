import React, { useRef } from "react";
import TeacherButton from "../../ui-component/TeacherBtn/TeacherButton";
import Input from "../../ui-component/Input/Input";
import AddFile from "./AddFile/AddFile";
import "./AddStudent.scss";
import { Api } from "../../api";
import { useNavigate } from "react-router-dom";
import InputDate from "../../ui-component/InputDate/InputDate";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputMask from "react-input-mask";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { Checkbox } from "@mui/material";
import CheckboxComponent from "../../ui-component/Checkbox/Checkbox";

export function AddStudent() {
  const [startDate, setStartDate] = React.useState();
  const [value, setValue] = React.useState("");
  const [schollData, setSchoolData] = React.useState([]);
  const [valueSel, setValueSel] = React.useState(null);
  const [isChecked, setIsChecked] = React.useState(false);
  const [providers, setProviders] = React.useState([]);

  async function fetchProviders() {
    try {
      const response = await axios.get(`https://teacher06.ru/api/providers`);
      return response.data;
    } catch (error) {
      console.error("Ошибка при запросе к API:", error);
      return [];
    }
  }

  React.useEffect(() => {
    fetchProviders()
      .then((providers) => {
        setProviders(providers.data);
      })
      .catch((error) => {
        console.error("Ошибка при получении списка предметов:", error);
      });
  }, []);

  async function fetchDisciplines() {
    try {
      const response = await axios.get(`https://teacher06.ru/api/schools`);
      return response.data;
    } catch (error) {
      console.error("Ошибка при запросе к API:", error);
      return [];
    }
  }

  React.useEffect(() => {
    fetchDisciplines()
      .then((schollData) => {
        setSchoolData(schollData);
      })
      .catch((error) => {
        console.error("Ошибка при получении списка предметов:", error);
      });
  }, []);

  const showErrorNotification = () => {
    toast.error("Заполните все обязательные поля.", {
      autoClose: 3000,
    });
  };

  const formattedSchollData = schollData?.map((item) => ({
    id: item.id,
    label: item.shortname,
  }));

  const options = formattedSchollData;

  const formRef = useRef(null);
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = React.useState(null);
  const [imageURL, setImageURL] = React.useState("");
  const fileInputRef = React.useRef(null);

  const handleImageChange = (e) => {
    e.preventDefault();
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setSelectedImage(selectedFile);
      const imageURL = URL.createObjectURL(selectedFile);
      setImageURL(imageURL);
    }
  };

  // Валидация на заполнение обязательных полей
  const validationFields = async () => {
    const requiredFields = formRef.current.querySelectorAll("[required]");

    let allFieldsFilled = true;

    requiredFields.forEach((field) => {
      if (!field.value.trim()) {
        allFieldsFilled = false;
      }
    });

    if (!allFieldsFilled) {
      showErrorNotification();
    }

    return allFieldsFilled;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    const selectedSchoolId = valueSel ? valueSel.id : null;
    formData.set("school", selectedSchoolId);
    formData.set("is_exists", isChecked ? "1" : "0");

    if (!(await validationFields())) {
      return;
    }

    await Api.student
      .addStudent(formData)
      .then(() => {
        navigate("/student");
        toast.success("Вы успешно добавили ученика!", {
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.error("Произошла ошибка при отправке формы:", error);
        toast.error("Что-то пошло не так", {
          autoClose: 3000,
        });
      });
  };

  const handleDateChange = (event) => {
    setStartDate(event.target.value);
  };

  return (
    <form ref={formRef}>
      <div className="form-container">
        <div>
          <AddFile
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            imageURL={imageURL}
            setImageURL={setImageURL}
            fileInputRef={fileInputRef}
            input={
              <input
                ref={fileInputRef}
                style={{ display: "none" }}
                name="photo"
                id="photo"
                type="file"
                onChange={handleImageChange}
              />
            }
          />
          <div className="fio-container">
            <Input
              required
              className={"input"}
              title="Фамилия"
              placeholder="Введите фамилию"
              name="surname"
            >
              <span title="Это поле обязательно" style={{ color: "red" }}>
                *
              </span>
            </Input>

            <Input
              required
              className={"input"}
              title="Имя"
              placeholder="Введите имя"
              name="name"
            >
              <span title="Это поле обязательно" style={{ color: "red" }}>
                *
              </span>
            </Input>
            <Input
              required
              className={"input"}
              title="Отчество"
              placeholder="Введите отчество"
              name="patronymic"
            >
              <span title="Это поле обязательно" style={{ color: "red" }}>
                *
              </span>
            </Input>
          </div>
          <div className="data-container">
            <div className="Multi-input-container">
              <InputDate
                required
                placeholder={"Дата рождения"}
                title={"Дата рождения"}
                name="born"
                value={startDate}
                onChange={handleDateChange}
              >
                <span title="Это поле обязательно" style={{ color: "red" }}>
                  *
                </span>
              </InputDate>

              <div className="pupils-class-container">
                <h4 className="">Пол</h4>
                <select placeholder="Выбрать" name="gender">
                  <option value="male">Мужской</option>
                  <option value="female">Женский</option>
                </select>
              </div>
            </div>
          </div>
          <div className="place-study-container">
            <h3>Место учебы</h3>
            <div
              style={{ display: "flex", alignItems: "center", width: "515px" }}
            >
              <div
                style={{ marginRight: "24px" }}
                className="pupils-class-container"
              >
                <h4 className="">Школа</h4>
                <Autocomplete
                  className="autocomplete-custom"
                  disablePortal
                  id="combo-box-demo"
                  options={options}
                  getOptionLabel={(option) => option.label} // Отображение сокращенного названия
                  getOptionValue={(option) => option.id} // Отправка id на сервер
                  value={valueSel} // Установка значения в компоненте
                  onChange={(event, newValue) => {
                    setValueSel(newValue); // Обновление значения при выборе
                  }}
                  sx={{ width: 314 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="school"
                      placeholder="Выберите школу"
                    />
                  )}
                />
              </div>
              <div className="place-study-container-class">
                <h4>Класс</h4>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  <select
                    className="selectClass-container-small"
                    name="pupil_class"
                  >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                  </select>
                  <Input name="liter" className="input3" placeholder="Литера" />
                </div>
              </div>
            </div>
          </div>
          <div className="provider__container">
            <h3>Дополнительное образование</h3>
            <div>
              <CheckboxComponent
                text={'проект "Код будущего"'}
                name="is_exists"
                isChecked={isChecked} // Передайте isChecked в компонент CheckboxComponent
                onCheckboxChange={() => setIsChecked(!isChecked)} // Обновите isChecked при изменении чекбокса
              >
                <select className="checkbox-select" name="provider_id">
                  {providers?.map((provider) => (
                    <option key={provider.id} value={provider.id}>
                      {provider.title}
                    </option>
                  ))}
                </select>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "12px" }}
                >
                  <Input
                    className={"input1"}
                    title="Номер заявления на госуслугах"
                    name={"statement"}
                    placeholder={"Введите номер заявления"}
                  ></Input>
                </div>
              </CheckboxComponent>
            </div>
          </div>
          <div className="pupils-contact">
            <h3>Контакт</h3>
            <div className="pupils-contact-block">
              <div className="pupils-contact-block-number">
                <div className="input-container">
                  <div style={{ display: "flex", gap: "5px" }}>
                    <label className="input-text">Номер ребенка</label>
                    <span title="Это поле обязательно" style={{ color: "red" }}>
                      *
                    </span>
                  </div>

                  <InputMask
                    required
                    className={"input1"}
                    style={{ border: "none" }}
                    mask="+7 (999) 999-99-99"
                    name={"child_phone"}
                    onChange={(e) => setValue(e.target.value)}
                    maskChar=""
                    placeholder="+7 (928) 321 22 22"
                  />
                </div>
                <div className="input-container">
                  <div style={{ display: "flex", gap: "5px" }}>
                    <label className="input-text">Номер родителя</label>
                    <span title="Это поле обязательно" style={{ color: "red" }}>
                      *
                    </span>
                  </div>

                  <InputMask
                    required
                    className={"input1"}
                    style={{ border: "none" }}
                    mask="+7 (999) 999-99-99"
                    name={"parent_phone"}
                    onChange={(e) => setValue(e.target.value)}
                    maskChar=""
                    placeholder="+7 (928) 321 22 22"
                  />
                </div>
              </div>

              <div
                style={{ display: "flex", alignItems: "center", gap: "12px" }}
              >
                <Input
                  className={"input1"}
                  title="Почта"
                  name={"email"}
                  placeholder={"zurab@mail.ru"}
                ></Input>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Add-block">
        <TeacherButton
          color={"var(--bw-900-w, #FFF)"}
          backcolor={"var(--primery-500, #2898EC)"}
          onClick={handleSubmit}
        >
          Добавить
        </TeacherButton>
        <TeacherButton
          color={"var(--bw-900-b, #000)"}
          backcolor={"var(--neutral-100, #F9F9F9)"}
        >
          Отмена
        </TeacherButton>
      </div>
      <div style={{ marginTop: "12px" }}>
        <span
          className="red-span"
          title="Это поле обязательно"
          style={{ color: "red" }}
        >
          * обязательные поля
        </span>
      </div>
    </form>
  );
}
