import { Endpoints } from "../constants/endpoints";
import axios from "../core/axios";

export const getTeacherProfile = async (id) => {
  return (await axios.get(`${Endpoints.TEACHER}/${id}`)).data;
};

export const addTeacher = async (form) => {
  return (await axios.post(Endpoints.TEACHER, form)).data;
};

export const getEditTeacher = async (id) => {
  return (await axios.get(`${Endpoints.TEACHER}${Endpoints.EDIT}/${id}`)).data;
}

export const EditTeacher = async (id, form) => {
  return (await axios.post(`${Endpoints.TEACHER}${Endpoints.EDIT}/${id}`, form)).data;
}

export const addStudent = async (form) => {
  return (await axios.post(Endpoints.STUDENT, form)).data;
}

export const getStudentProfile = async (id) => {
  return (await axios.get(`${Endpoints.STUDENT}/${id}`)).data;
};

export const getEditStudent = async (id) => {
  return (await axios.get(`${Endpoints.STUDENT}/${id}`)).data;
}

export const EditStudent = async (id, form) => {
  return (await axios.post(`${Endpoints.STUDENT}/${id}`, form)).data;
}