import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#2898ec'
        },
    },

    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '8px', // Replace with your preferred value
                    boxShadow: 'none', // Replace with your preferred box-shadow
                    fontFamily: 'Nunito Sans',
                    fontStyle: 'normal',
                    textTransform: 'none',
                    fontSize: 16,
                    fontWeight: 500,
                },
            },
        },
    },
});