import React from "react";
import { MainLayout } from "../../layouts/main-layout";
import { Student } from "../../components/Student/Student";
import { Outlet } from "react-router-dom";

export const StudentPage = () => {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};
