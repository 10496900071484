import React from "react";
import AddFile from "../AddTeacher/AddFile/AddFile";
import Input from "../../ui-component/Input/Input";
import InputDate from "../../ui-component/InputDate/InputDate";
import TeacherButton from "../../ui-component/TeacherBtn/TeacherButton";
import InputMask from "react-input-mask";
import { errorNotification } from "../../utils/errorNotification";
import { Api } from "../../api";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Collapse } from "../../ui-component/Collapse/Collapse";
import CheckboxComponent from "../../ui-component/Checkbox/Checkbox";
import { Place } from "../Place/Place";
import Education from "../Education/Education";
import Experience from "../Experience/Experience";
import PlusSvg from "../../assets/icons/plus.svg";
import ArrowSvg from "../../assets/icons/Arrow.svg";
import { toast } from "react-toastify";
import classNames from "classnames";

const RANKS = [
  "Заслуженный учитель Ингушетии",
  "Заслуженный учитель России",
  "Почетный работник образования",
  "Отличник народного просвещения",
  "Награжденный Грамотой Министерства образования РФ",
];

export default function EditTeacher({ isChecked }) {
  const { id } = useParams();
  const [startDate, setStartDate] = React.useState();
  const [educations, setEducations] = React.useState([]);
  const [experiences, setExperiences] = React.useState([]);
  const [educationBlock, setEducationBlock] = React.useState(true);
  const [experienceBlock, setExperienceBlock] = React.useState(true);
  const [disciplines, setDisciplines] = React.useState([]);
  const [teachers, setTeachers] = React.useState({});
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [imageURL, setImageURL] = React.useState("");
  const [values, setValues] = React.useState({});
  const fileInputRef = React.useRef(null);
  const formRef = React.useRef(null);
  const [value, setValue] = React.useState("");
  const [valueWats, setValueWats] = React.useState(value);
  const [matches, setMatches] = React.useState(false);
  const [degree, setDegree] = React.useState("");
  const [skill, setSkill] = React.useState("");
  const [newEducation, setNewEducation] = React.useState("");

  const navigate = useNavigate();

  const handleMatchesClick = () => {
    setMatches(!matches);
  };

  React.useEffect(() => {
    setSelectedImage(teachers?.photo);
  }, [teachers]);

  const handleImageChange = (e) => {
    e.preventDefault();
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setSelectedImage(selectedFile);
      const imageURL = URL.createObjectURL(selectedFile);
      setImageURL(imageURL);
    }
  };

  React.useEffect(() => {
    if (matches) {
      setValueWats(value);
    } else {
      setValueWats("");
    }
  }, [matches]);

  const handleInputChange = (fieldName, value) => {
    setTeachers((prevTeachers) => ({
      ...prevTeachers,
      [fieldName]: value,
    }));
  };

  const onChangeEducation = (idx) => {
    const newEducations = [...educations];
    newEducations[idx] = !newEducations[idx];
    setEducations(newEducations);
  };

  function onAddEducation(e) {
    e.preventDefault();
    setEducations([
      ...educations,
      {
        type: "среднее профессиональное образование",
        university: "",
        from: "",
        to: "",
      },
    ]);
  }

  const onChangeEducation1 = (idx) => {
    const newEducations1 = [...experiences];
    newEducations1[idx] = !newEducations1[idx];

    setExperiences(newEducations1);
  };

  function onRemoveEducation(e, idx) {
    e.preventDefault();
    setEducations(educations?.filter((_, index) => index !== idx));
  }

  function onRemoveExperience(e, idx) {
    e.preventDefault();
    if (experiences.length === 1) {
      return;
    }
    setExperiences(experiences?.filter((_, i) => idx !== i));
  }

  function onAddEducation1(e) {
    e.preventDefault();
    setExperiences([
      ...experiences,
      {
        work: "",
        post: "",
        from: "",
        to: "",
      },
    ]);
  }

  const fetchUserProfile = async () => {
    try {
      const data = await Api.teachers.getEditTeacher(id);
      setTeachers(data);
      setEducations(data.education);
      setDisciplines(data.discipline);
      setExperiences(data.works ?? []);
      setDegree(data.degree);
      setSkill(data.skill);
      setNewEducation(data.education);
      setImageURL(data.photo || "");
    } catch (error) {
      errorNotification(error);
    }
  };

  React.useEffect(() => {
    if (id) {
      fetchUserProfile();
    }
  }, [id]);

  if (!teachers) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    disciplines?.forEach((item) => formData.append("discipline[]", item));
    teachers?.ranks?.forEach((item) => formData.append("ranks[]", item));
    // educations.forEach((item, index) => {
    //   formData.append(`educations[${index}][type]`, item.type ?? "");
    //   formData.append(
    //     `educations[${index}][university]`,
    //     item.university ?? ""
    //   );
    //   formData.append(`educations[${index}][from]`, item.from ?? "");
    //   formData.append(`educations[${index}][to]`, item.to ?? "");
    // });
    // experiences?.forEach((item, index) => {
    //   formData.append(`works[${index}][work]`, item.work ?? "");
    //   formData.append(`works[${index}][post]`, item.post ?? "");
    //   formData.append(`works[${index}][from]`, item.from ?? "");
    //   formData.append(`works[${index}][to]`, item.to ?? "");
    // });
    formData.append("firstname", teachers.firstname ?? "");
    formData.append("name", teachers.name ?? "");
    formData.append("patronymic", teachers.patronymic ?? "");
    formData.append("photo", teachers.photo ?? "");
    formData.append("born", teachers.born ?? "");
    formData.append("phone", teachers.phone ?? "");
    formData.append("email", teachers.email ?? "");
    formData.append("whatsapp", teachers.whatsapp ?? "");
    formData.append("teacher_experience", teachers.teacher_experience ?? "");
    formData.append("post_experience", teachers.post_experience ?? "");
    formData.append("skill", teachers.skill ?? "");
    formData.append("degree", teachers.degree ?? "");

    // ranks, works

    try {
      await Api.teachers
        .EditTeacher(id, formData)
        .then((updatedTeacherData) => {
          setTeachers(updatedTeacherData);
          navigate("/state");
          toast.success("Вы успешно обновили данные учителя!", {
            autoClose: 3000,
          });
        });
    } catch (error) {
      console.error("Произошла ошибка при отправке формы:", error);
      toast.error("Что-то пошло не так", {
        autoClose: 3000,
      });
    }
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <div className="form-container">
        <div>
          <AddFile
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            imageURL={imageURL || teachers?.photo}
            setImageURL={setImageURL}
            fileInputRef={fileInputRef}
            input={
              <input
                ref={fileInputRef}
                style={{ display: "none" }}
                name="photo"
                id="photo"
                type="file"
                onChange={handleImageChange}
              />
            }
          />
          <div className="fio-container">
            <Input
              value={teachers?.firstname}
              className={"input"}
              width={205}
              title="Фамилия"
              placeholder="Введите фамилию"
              name="firstname"
              onChange={(e) => handleInputChange("firstname", e.target.value)}
            ></Input>

            <Input
              value={teachers?.name}
              className={"input"}
              width={205}
              title="Имя"
              placeholder="Введите имя"
              name="name"
              onChange={(e) => handleInputChange("name", e.target.value)}
            ></Input>
            <Input
              value={teachers?.patronymic}
              className={"input"}
              width={205}
              title="Отчество"
              placeholder="Введите отчество"
              name="patronymic"
              onChange={(e) => handleInputChange("patronymic", e.target.value)}
            ></Input>
          </div>
          <div className="data-container">
            <div className="Multi-input-container">
              <InputDate
                placeholder={"Дата рождения"}
                title={"Дата рождения"}
                name="born"
                value={teachers?.born}
                onChange={(e) => handleInputChange("born", e.target.value)}
              ></InputDate>
            </div>
          </div>
          <div className="Contact-Addteacher">
            <div className="contact__container">
              <h3 className="contact__title">Контакты</h3>
              <div className="contact__input-block">
                <div style={{ display: "flex", gap: "12px" }}>
                  <div className="input-container">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <label className="input-text">Номер</label>
                    </div>

                    <InputMask
                      value={teachers?.phone}
                      className={"input1"}
                      style={{ border: "none" }}
                      mask="+7 (999) 999-99-99"
                      name={"phone"}
                      onChange={(e) =>
                        handleInputChange("phone", e.target.value)
                      }
                      maskChar=""
                      placeholder="+7 (928) 321 22 22"
                    />
                  </div>
                  <Input
                    value={teachers?.email}
                    className={"input1"}
                    title="Почта"
                    name={"email"}
                    placeholder={"ya@mail.ru"}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                  ></Input>
                </div>

                <div className="input-container">
                  <div style={{ display: "flex", gap: "5px" }}>
                    <label className="input-text">Whatsapp</label>
                  </div>

                  <InputMask
                    className={"input1"}
                    style={{ border: "none" }}
                    value={teachers?.whatsapp}
                    mask="+7 (999) 999-99-99"
                    name={"whatsapp"}
                    disabled={matches}
                    onChange={(e) =>
                      handleInputChange("whatsapp", e.target.value)
                    }
                    maskChar=""
                    placeholder="+7 (928) 321 22 22"
                  />
                </div>
              </div>
              <div className="contact-checkbox">
                <CheckboxComponent
                  text="Совпадает с основным номером"
                  handleClick={handleMatchesClick}
                />
              </div>
            </div>
          </div>
          <div className="place-container-block">
            <h3 className="place-title-head">Должность</h3>
            <div className="place-container">
              <Place
                postTypes={teachers.post_type}
                value={value}
                disciplines={disciplines}
                setDisciplines={setDisciplines}
                teachers={teachers}
                onChange={(e) => handleInputChange("whatsapp", e.target.value)}
              />
            </div>
          </div>
          <div className="probation-container">
            <h3 className="place-title-head">Стаж</h3>
            <div className="probation-block">
              <Input
                value={teachers?.teacher_experience}
                className={"input2"}
                title="Педагогический стаж"
                placeholder="Введите стаж"
                name="teacher_experience"
                onChange={(e) =>
                  handleInputChange("teacher_experience", e.target.value)
                }
              />
              <Input
                value={teachers?.post_experience}
                className={"input2"}
                title="Стаж в данной должности"
                placeholder="Введите стаж"
                name="post_experience"
                onChange={(e) =>
                  handleInputChange("post_experience", e.target.value)
                }
              />
            </div>
          </div>
          <div className="merit-container">
            <h3 className="place-title-head">Заслуги</h3>
            <div className="merit-block">
              <div className="class-container">
                <h4>Ученая степень</h4>
                <select
                  value={teachers?.degree}
                  onChange={(e) =>
                    setTeachers({ ...teachers, degree: e.target.value })
                  }
                  name="degree"
                >
                  <option value="">Нет</option>
                  <option value="Первая">Кандидат наук</option>
                  <option value="Высшая">Доктор наук</option>
                </select>
              </div>
              <div className="class-container">
                <h4>Категория</h4>
                <select
                  value={teachers?.skill}
                  onChange={(e) =>
                    setTeachers({ ...teachers, skill: e.target.value })
                  }
                  name="skill"
                >
                  <option value="">Нет</option>
                  <option value="Первая">Первая</option>
                  <option value="Высшая">Высшая</option>
                </select>
              </div>
            </div>
          </div>
          <div className="ranks-container">
            <h4>Звания</h4>
            <div>
              {RANKS?.map((item) => (
                <CheckboxComponent
                  value={item}
                  handleClick={() => {
                    if (teachers.ranks && teachers.ranks.includes(item)) {
                      setTeachers({
                        ...teachers,
                        ranks: teachers.ranks.filter((el) => el != item),
                      });
                    } else {
                      setTeachers({
                        ...teachers,
                        ranks: [...(teachers.ranks ?? []), item],
                      });
                    }
                  }}
                  text={item}
                  defaultChecked={
                    teachers.ranks && teachers.ranks.includes(item)
                  }
                />
              ))}
            </div>
          </div>

          <div className="AddTeacher-container">
            <div className="collapse-block-arrow">
              <h3 className="collapse__title-block">Образование</h3>
              <img
                onClick={() => setEducationBlock(!educationBlock)}
                src={ArrowSvg}
                alt="arrow"
              />
            </div>
            <div
              className={classNames("collapse__container", {
                "collapse__container-visible": educationBlock,
              })}
            >
              {educations?.map((education, idx) => (
                <>
                  <Collapse
                    isOpen={education}
                    key={idx}
                    onToggle={() => onChangeEducation(idx)}
                    title={"Образование " + (idx + 1)}
                    children={
                      <Education
                        // onInputChange={(fieldName, value) =>
                        //   handleEducationChange(idx, fieldName, value)
                        // }
                        setEducations={setEducations}
                        title={"Образование " + (idx + 1)}
                        onRemoveEducation={(e) => onRemoveEducation(e, idx)}
                        index={idx}
                        education={education}
                        educations={educations}
                      />
                    }
                  />
                </>
              ))}
              <TeacherButton
                color={"var(--bw-900-b, #000)"}
                backcolor={"var(--neutral-100, #F9F9F9)"}
                onClick={onAddEducation}
              >
                <img src={PlusSvg} alt="" />
                Добавить образование
              </TeacherButton>
            </div>
          </div>
        </div>
        <div>
          <div className="AddTeacher-container">
            <div className="collapse-block-arrow">
              <h3 className="collapse__title-block">Опыт работы</h3>
              <img
                onClick={() => setExperienceBlock(!experienceBlock)}
                src={ArrowSvg}
                alt="arrow"
              />
            </div>
            {experienceBlock && (
              <>
                <div>
                  {experiences?.map((education1, idx) => (
                    <Collapse
                      isOpen={education1}
                      onToggle={() => onChangeEducation1(idx)}
                      title={"Работа " + (idx + 1)}
                      children={
                        <Experience
                          title={"Работа " + (idx + 1)}
                          onRemoveExperience={(e) => onRemoveExperience(e, idx)}
                          index={idx}
                          works={education1}
                        />
                      }
                    />
                  ))}
                </div>

                <TeacherButton
                  color={"var(--bw-900-b, #000)"}
                  backcolor={"var(--neutral-100, #F9F9F9)"}
                  onClick={onAddEducation1}
                >
                  <img src={PlusSvg} alt="plus" />
                  Добавить место работы
                </TeacherButton>
              </>
            )}
          </div>
        </div>

        <div className="Add-block">
          <TeacherButton
            color={"var(--bw-900-w, #FFF)"}
            backcolor={"var(--primery-500, #2898EC)"}
            type="submit"
          >
            Добавить
          </TeacherButton>
          <TeacherButton
            color={"var(--bw-900-b, #000)"}
            backcolor={"var(--neutral-100, #F9F9F9)"}
          >
            Отмена
          </TeacherButton>
        </div>
        <div style={{ marginTop: "12px" }}></div>
      </div>
    </form>
  );
}
