import React from "react";
import FormTeacher from "../Forms/FormTeacher/FormTeacher";
import "./SelectGroup.scss";
import Input from "../Input/Input";
import axios from "axios";

export default function SelectGroup({
  disciplines,
  teachers,
  setDisciplines,
  required,
  onChangePostType,
  postType,
  idx,
}) {
  const [selectValue, setSelectValue] = React.useState("");
  const [posts, setPosts] = React.useState([]);

  async function fetchPosts() {
    try {
      const response = await axios.get(`https://teacher06.ru/api/posts`);
      return response.data;
    } catch (error) {
      console.error("Ошибка при запросе к API:", error);
      return [];
    }
  }

  React.useEffect(() => {
    fetchPosts()
      .then((posts) => {
        setPosts(posts.data);
      })
      .catch((error) => {
        console.error("Ошибка при получении списка предметов:", error);
      });
  }, []);

  React.useEffect(() => {
    if (postType) {
      setSelectValue(postType);
    }
  }, [postType]);

  const handleOptionChange = (e) => {
    setSelectValue(e.target.value);
    onChangePostType(e.target.value, idx);
  };

  const renderSelectedComponent = () => {
    if (postType === "Выбрать") {
      return null;
    } else if (postType === "1") {
      return null;
    } else if (postType === "2") {
      return null;
    } else if (postType === "3") {
      return null;
    } else if (postType === "4") {
      return (
        <FormTeacher
          teachers={teachers}
          name="discipline"
          disciplines={disciplines}
          setDisciplines={setDisciplines}
        />
      );
    } else if (postType === "5") {
      return null;
    } else if (postType === "6") {
      return null;
    } else if (postType === "7") {
      return null;
    } else if (postType === "8") {
      return null;
    } else if (postType === "9") {
      return null;
    } else if (postType === "10") {
      return null;
    } else if (postType === "11") {
      return null;
    } else if (postType === "12") {
      return null;
    } else if (postType === "13") {
      return null;
    } else if (postType === "14") {
      return null;
    } else if (postType === "15") {
      return null;
    } else if (postType === "16") {
      return null;
    } else if (postType === "17") {
      return null;
    } else if (postType === "18") {
      return (
        <Input
          required
          className={"input2"}
          placeholder="Введите должность"
          name="post"
        />
      );
    }
  };

  return (
    <div className="select-group-title-container">
      <div>
        <select
          className="select-group"
          required={required}
          name="post_type[]"
          placeholder="Выберите должность"
          value={selectValue}
          onChange={handleOptionChange}
        >
          {posts.map((post) => (
            <option key={post.id} value={post.id}>
              {post.title}
            </option>
          ))}
        </select>
      </div>

      <div className="render-component">{renderSelectedComponent()}</div>
    </div>
  );
}
