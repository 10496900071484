import React from "react";
import "./Further.scss";
import CheckboxComponent from "../Checkbox/Checkbox";
import Input from "../Input/Input";
import Remove from "../../assets/icons/remove.svg";
import PlusSvg from "../../assets/icons/plus.svg";
import TeacherButton from "../TeacherBtn/TeacherButton";

export function FurtherComponent({
  teachers,
  value,
  classNumber,
  classLatter,
}) {
  const [form, setForm] = React.useState({
    subdiscipline: [],
  });

  React.useEffect(() => {
    if (teachers) {
      setForm({
        subdiscipline: teachers?.subdiscipline ?? [],
      });
    }
  }, [teachers]);

  const [additionalEducations, setAdditionalEducations] = React.useState([]);
  const [isChecked, setIsChecked] = React.useState(false);

  const handleInputChange = (value, index) => {
    const updatedSubdiscipline = [...form.subdiscipline];
    updatedSubdiscipline[index] = value;
    setForm({ ...form, subdiscipline: updatedSubdiscipline });
  };

  const onChangeInput = (value, index) => {
    const newValue = [...additionalEducations];
    newValue[index] = value;
    setAdditionalEducations(newValue);
  };

  const onAddAdditionalEducation = (e) => {
    e.preventDefault();
    setForm({ ...form, subdiscipline: [...form.subdiscipline, ""] });
  };

  const onRemoveAdditionalEducation = (index, e) => {
    e.preventDefault();

    if (form.subdiscipline.length === 1) {
      return;
    }

    const updatedSubdiscipline = [...form.subdiscipline];
    updatedSubdiscipline.splice(index, 1);
    setForm({ ...form, subdiscipline: updatedSubdiscipline });
  };

  React.useEffect(() => {
    if (teachers?.subdiscipline) {
      setIsChecked(true);
    }
  }, [teachers]);

  return (
    <div className="App">
      <div className="CheckboxComponent-container">
        <CheckboxComponent
          defaultChecked={isChecked}
          text={"Учитель доп. образования"}
        >
          <div className="selectedClass-block">
            <h4 className="select-text-checkbox">Учитель доп. образования</h4>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                alignItems: "flex-start",
              }}
              className="select-checkbox-block"
            >
              {form.subdiscipline.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    width: "100%",
                  }}
                >
                  <Input
                    value={item}
                    onChange={(e) => handleInputChange(e.target.value, index)}
                    className="input3"
                    name={`subdiscipline[${index}]`}
                    placeholder="Пример: робототехника"
                  />
                  {index !== 0 && (
                    <button
                      onClick={(e) => onRemoveAdditionalEducation(index, e)}
                      className="select-checkbox-block-btn"
                    >
                      <img src={Remove} alt="Удалить" />
                    </button>
                  )}
                </div>
              ))}
            </div>
            <TeacherButton
              containerProps={{ style: { width: "100%" } }}
              color={"var(--bw-900-b, #000)"}
              backcolor={"var(--neutral-100, #F9F9F9)"}
              onClick={onAddAdditionalEducation}
            >
              <img src={PlusSvg} alt="" />
              Добавить поле
            </TeacherButton>
          </div>
        </CheckboxComponent>
      </div>
    </div>
  );
}
