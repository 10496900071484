import React from "react";
import AddFile from "../AddTeacher/AddFile/AddFile";
import Input from "../../ui-component/Input/Input";
import InputDate from "../../ui-component/InputDate/InputDate";
import TeacherButton from "../../ui-component/TeacherBtn/TeacherButton";
import InputMask from "react-input-mask";
import { errorNotification } from "../../utils/errorNotification";
import { Api } from "../../api";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./EditStudent.scss";
import CheckboxComponent from "../../ui-component/Checkbox/Checkbox";
import axios, { Axios } from "axios";
import { Autocomplete, TextField } from "@mui/material";

export default function EditStudent() {
  const { id } = useParams();
  const [student, setStudent] = React.useState({ school: {} });
  const [selectedImage, setSelectedImage] = React.useState(null);
  const [imageURL, setImageURL] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(!!student.provider_id);
  const [providers, setProviders] = React.useState([]);
  const [schollData, setSchoolData] = React.useState([]);

  const fileInputRef = React.useRef(null);
  const formRef = React.useRef(null);

  const navigate = useNavigate();

  async function fetchDisciplines() {
    try {
      const response = await axios.get(`https://teacher06.ru/api/schools`);
      return response.data;
    } catch (error) {
      console.error("Ошибка при запросе к API:", error);
      return [];
    }
  }

  React.useEffect(() => {
    fetchDisciplines()
      .then((schollData) => {
        setSchoolData(schollData);
      })
      .catch((error) => {
        console.error("Ошибка при получении списка предметов:", error);
      });
  }, []);

  const formattedSchollData = schollData?.map((item) => ({
    id: item.id,
    label: item.shortname,
  }));

  const options = formattedSchollData;

  async function fetchProviders() {
    try {
      const response = await axios.get(`https://teacher06.ru/api/providers`);
      return response.data;
    } catch (error) {
      console.error("Ошибка при запросе к API:", error);
      return [];
    }
  }

  React.useEffect(() => {
    fetchProviders()
      .then((providers) => {
        setProviders(providers.data);
      })
      .catch((error) => {
        console.error("Ошибка при получении списка предметов:", error);
      });
  }, []);

  React.useEffect(() => {
    setSelectedImage(student?.photo);
  }, [student]);

  React.useEffect(() => {
    setIsChecked(!!student.provider_id);
  }, [student.provider_id]);

  const handleImageChange = (e) => {
    e.preventDefault();
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setSelectedImage(selectedFile);
      const imageURL = URL.createObjectURL(selectedFile);
      setImageURL(imageURL);
    }
  };

  const handleInputChange = (fieldName, value) => {
    setStudent((prevStudent) => ({
      ...prevStudent,
      [fieldName]: value,
    }));
  };

  const fetchUserProfile = async () => {
    try {
      const data = await Api.student.getEditStudent(id);
      setImageURL(data.photo || "");
      setStudent(data);
    } catch (error) {
      errorNotification(error);
    }
  };

  React.useEffect(() => {
    if (id) {
      fetchUserProfile();
    }
  }, [id]);

  if (!student) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(formRef.current);
    formData.append("surname", student.surname ?? "");
    formData.append("name", student.name ?? "");
    formData.append("patronymic", student.patronymic ?? "");
    formData.append("photo", student.photo ?? "");
    formData.append("born", student.born ?? "");
    formData.append("child_phone", student.child_phone ?? "");
    formData.append("parent_phone", student.parent_phone ?? "");
    formData.append("liter", student.liter ?? "");
    formData.append("pupil_class", student.pupil_class ?? "");
    formData.append("provider_id", student.provider_id ?? "");
    formData.append("school", student.school.id || "");
    formData.append("gender", student.gender || "");

    try {
      await Api.student.EditStudent(id, formData).then((updatedStudentData) => {
        setStudent(updatedStudentData);
        navigate("/student");
        toast.success("Вы успешно обновили данные ученика!", {
          autoClose: 3000,
        });
      });
    } catch (error) {
      console.error("Произошла ошибка при отправке формы:", error);
      toast.error("Что-то пошло не так", {
        autoClose: 3000,
      });
    }
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <div className="form-container">
        <div>
          <AddFile
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            imageURL={imageURL || student?.photo}
            setImageURL={setImageURL}
            fileInputRef={fileInputRef}
            input={
              <input
                ref={fileInputRef}
                style={{ display: "none" }}
                name="photo"
                id="photo"
                type="file"
                onChange={handleImageChange}
              />
            }
          />
          <div className="fio-container">
            <Input
              value={student?.surname}
              className={"input"}
              width={205}
              title="Фамилия"
              placeholder="Введите фамилию"
              name="firstname"
              onChange={(e) => handleInputChange("surname", e.target.value)}
            ></Input>

            <Input
              value={student?.name}
              className={"input"}
              width={205}
              title="Имя"
              placeholder="Введите имя"
              name="name"
              onChange={(e) => handleInputChange("name", e.target.value)}
            ></Input>
            <Input
              value={student?.patronymic}
              className={"input"}
              width={205}
              title="Отчество"
              placeholder="Введите отчество"
              name="patronymic"
              onChange={(e) => handleInputChange("patronymic", e.target.value)}
            />
          </div>
          <div className="data-container">
            <div className="Multi-input-container">
              <InputDate
                className="datapicker"
                placeholder={"Дата рождения"}
                title={"Дата рождения"}
                name="born"
                value={student?.born}
                onChange={(e) => handleInputChange("born", e.target.value)}
              />
            </div>
            <div className="place-study-container-class">
              <h4>Класс</h4>
              <div
                style={{ display: "flex", alignItems: "center", gap: "12px" }}
              >
                <select
                  value={student?.pupil_class}
                  className="selectClass-container-small"
                  name="pupil_class"
                  onChange={(e) =>
                    setStudent({ ...student, pupil_class: e.target.value })
                  }
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                </select>
                <Input
                  value={student?.liter}
                  name="liter"
                  className="input3"
                  placeholder="Литера"
                  onChange={(e) => handleInputChange("liter", e.target.value)}
                />
              </div>
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
            <div
              style={{ marginRight: "24px" }}
              className="pupils-class-container"
            >
              <h4 className="">Школа</h4>
              <Autocomplete
                value={student?.school?.shortname || ""}
                className="autocomplete-custom"
                disablePortal
                id="combo-box-demo"
                options={options}
                sx={{ width: 314 }}
                isOptionEqualToValue={(option, value) => option.value === value}
                onChange={(_, newValue) => {
                  if (newValue) {
                    setStudent((prevStudent) => ({
                      ...prevStudent,
                      school: {
                        ...prevStudent.school,
                        id: newValue.id, // Устанавливаем ID школы в состояние
                        shortname: newValue.label, // Устанавливаем сокращенное название школы в состояние
                      },
                    }));
                  } else {
                    setStudent((prevStudent) => ({
                      ...prevStudent,
                      school: {}, // Очищаем данные о школе, если выбрано "Пусто"
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="school"
                    placeholder="Выберите школу"
                  />
                )}
              />
            </div>
            <div className="place-study-container-class">
              <h4>Пол</h4>
              <div
                style={{ display: "flex", alignItems: "center", gap: "12px" }}
              >
                <select
                  value={student?.gender}
                  className="selectClass"
                  name="gender"
                  onChange={(e) =>
                    setStudent({ ...student, gender: e.target.value })
                  }
                >
                  <option value="">Выбрать</option>
                  <option value="male">Мужской</option>
                  <option value="female">Женский</option>
                </select>
              </div>
            </div>
          </div>
          <div>
            <CheckboxComponent
              defaultChecked={isChecked}
              text={'проект "Код будущего"'}
            >
              <select
                onChange={(e) => {
                  const selectedProviderId = e.target.value;
                  setStudent((prevStudent) => ({
                    ...prevStudent,
                    provider_id: selectedProviderId,
                  }));
                }}
                value={student?.provider_id}
                className="checkbox-select"
                name="provider_id"
              >
                {providers?.map((provider) => (
                  <option key={provider.id} value={provider.id}>
                    {provider.title}
                  </option>
                ))}
              </select>
              <Input
                title={"Номер заявления на госуслугах"}
                value={student?.statement}
                name="statement"
                className="input3"
                placeholder="Литера"
                onChange={(e) => handleInputChange("statement", e.target.value)}
              />
            </CheckboxComponent>
          </div>

          <div className="Contact-Addteacher">
            <div className="contact__container">
              <h3 className="contact__title">Контакты</h3>
              <div className="contact__input-block">
                <div style={{ display: "flex", gap: "12px" }}>
                  <div className="input-container">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <label className="input-text">Номер ребенка</label>
                    </div>

                    <InputMask
                      value={student?.child_phone}
                      className={"input1"}
                      style={{ border: "none" }}
                      mask="+7 (999) 999-99-99"
                      name={"child_phone"}
                      onChange={(e) =>
                        handleInputChange("child_phone", e.target.value)
                      }
                      maskChar=""
                      placeholder="+7 (928) 321 22 22"
                    />
                  </div>
                  <div className="input-container">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <label className="input-text">Номер родителя </label>
                    </div>

                    <InputMask
                      value={student?.parent_phone}
                      className={"input1"}
                      style={{ border: "none" }}
                      mask="+7 (999) 999-99-99"
                      name={"parent_phone"}
                      onChange={(e) =>
                        handleInputChange("parent_phone", e.target.value)
                      }
                      maskChar=""
                      placeholder="+7 (928) 321 22 22"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="Add-block">
          <TeacherButton
            color={"var(--bw-900-w, #FFF)"}
            backcolor={"var(--primery-500, #2898EC)"}
            type="submit"
          >
            Сохранить
          </TeacherButton>
          <TeacherButton
            color={"var(--bw-900-b, #000)"}
            backcolor={"var(--neutral-100, #F9F9F9)"}
          >
            Отмена
          </TeacherButton>
        </div>
        <div style={{ marginTop: "12px" }}></div>
      </div>
    </form>
  );
}
