import React from "react";
import "./SelectedClass.scss";
import CheckboxComponent from "../Checkbox/Checkbox";
import Input from "../Input/Input";

export function SelectedClass({ teachers }) {
  const [selectedOption, setSelectedOption] = React.useState("");
  const [selectedOption1, setSelectedOption1] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(false);

  const [form, setForm] = React.useState({
    class: "",
    liter: "",
  });

  React.useEffect(() => {
    if (teachers) {
      setForm({
        class: teachers?.class ?? "",
        liter: teachers?.liter ?? "",
      });
    }
  }, [teachers]);

  // React.useEffect(() => {
  //   if (teachers?.class && teachers?.liter) {
  //     setIsChecked(true);
  //   }
  // }, [teachers]);

  const handleInputChange = (value, fieldName) => {
    setForm({ ...form, [fieldName]: value });
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOptionChange1 = (event) => {
    setSelectedOption1(event.target.value);
  };



  return (
    <div className="App">
      <div className="CheckboxComponent-container">
        <CheckboxComponent
          defaultChecked={isChecked}
          text={"Классный руководитель"}
        >
          <div className="selectedClass-block">
            <h4 className="select-text-checkbox">Классный руководитель</h4>
            <div className="select-checkbox-block">
              <select
                onChange={(e) => handleInputChange(e.target.value, "class")}
                className="selectClass-container-small"
                value={form.class}
                name="class"
              >
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
              </select>
              {selectedOption && undefined}
              <Input
                className="input2 input4"
                name="liter"
                placeholder="Литера"
                value={form.liter}
                onChange={(e) => handleInputChange(e.target.value, "liter")}
              />
              {/* <select
              className="selectClass-container"
              value={selectedOption1}
              onChange={handleOptionChange1}
              name="liter"
            >
              <option value="А">А</option>
              <option value="Б">Б</option>
              <option value="В">В</option>
              <option value="Г">Г</option>
              <option value="Д">Д</option>
            </select>
            {selectedOption && undefined} */}
            </div>
          </div>
        </CheckboxComponent>
      </div>
    </div>
  );
}
