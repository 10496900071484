import React from "react";
import cn from "classnames";
import Remove from "../../assets/icons/remove.svg";

import "./Collapse.scss";

export function Collapse({
  title,
  children,
  isOpen,
  index,
  onRemoveEducation,
}) {
  function handleClick(event) {
    event.preventDefault();
  }

  return (
    <div className="collapse">
      {isOpen && <div className="collapse__content">{children}</div>}
    </div>
  );
}
