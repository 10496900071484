import * as auth from "./auth";
import * as teachers from "./teacher";
import * as settings from './settings'
import * as student from './teacher'

export const Api = {
  auth,
  teachers,
  settings,
  student
};
