import React from "react";
import SelectComponent from "react-select";
import Autocomplete from "@mui/material/Autocomplete";
import "./MultiSelected.scss";
import { TextField } from "@mui/material";
import axios from "axios";

export default function MultiSelected({
  onChange,
  disciplines,
  setDisciplines,
  value,
}) {
  const [disciplinesData, setDisciplinesData] = React.useState([]);

  async function fetchDisciplines() {
    try {
      const response = await axios.get(`https://teacher06.ru/api/disciplines`);
      return response.data;
    } catch (error) {
      console.error("Ошибка при запросе к API:", error);
      return [];
    }
  }

  React.useEffect(() => {
    fetchDisciplines()
      .then((disciplinesData) => {
        setDisciplinesData(disciplinesData.data);
      })
      .catch((error) => {
        console.error("Ошибка при получении списка предметов:", error);
      });
  }, []);

  const formattedDisciplinesData = disciplinesData?.map((discipline) => ({
    value: discipline.id,
    label: discipline.title,
  }));

  const options = formattedDisciplinesData;

  const InputStyle = {
    backgroundColor: "var(--neutral-100, #F9F9F9)",
    borderRadius: "8px",
    fontFamily: "Nunito Sans",
    fontSize: "16px",
    color: "var(--neutral-400, #5C5C5C)",
    borderRadius: "4px",
    outline: "none",
  };

  const handleAutocompleteChange = (_, newValue) => {
    setDisciplines(
      newValue.map((option) => {
        if (option.value) {
          return option.label;
        } else {
          return option;
        }
      })
    );
  };

  return (
    <div className="multiSel-container">
      <h4 className="">Предмет преподавания</h4>
      <Autocomplete
        value={disciplines ? disciplines : value}
        multiple
        style={InputStyle}
        id="tags-outlined"
        options={options}
        classes={{ root: "root" }}
        onChange={handleAutocompleteChange}
        isOptionEqualToValue={(option, value) => option.value === value}
        renderInput={(params) => (
          <TextField {...params} placeholder="Выберите предмет" />
        )}
      />
    </div>
  );
}
