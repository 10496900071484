import React from "react";
import { getGender, calculateAge } from "../../utils/table";
import { Link } from "react-router-dom";
import "./Student.scss";

export function StudentCell({
  shortname,
  pupil_class,
  photo,
  index,
  surname,
  name,
  patronymic,
  born,
  gender,
  id,
  child_phone,
}) {
  return (
    <tr>
      <td className="TableTd">{index + 1}</td>
      <td className="TableTd">
        <Link className="TableLink" to={`/student/profile/${id}`}>
          <div
            style={{
              width: "28px",
              height: "28px",
              borderRadius: "28px",
              overflow: "hidden",
            }}
          >
            <img className="img-user-teacher" src={photo} />
          </div>
          {surname} {name} {patronymic}
        </Link>
      </td>
      <td className="TableTd">{shortname}</td>
      <td className="TableTd">{pupil_class}</td>
      <td className="TableTd">{calculateAge(born)}</td>
      <td className="TableTd">{getGender(gender)}</td>
      <td className="TableTd">{child_phone}</td>
    </tr>
  );
}
