import React from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api";
import { RegistrationContext } from "../../context";
import { RiCalendarLine } from "react-icons/ri";
import { errorNotification } from "../../utils/errorNotification";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import "./ProfileStudent.scss";
import { calculateAge } from "../../utils/table";

export const ProfileStudent = () => {
  const { id } = useParams();
  const { user } = React.useContext(RegistrationContext);

  const isMe = id === user?.id;

  const [profile, setProfile] = React.useState(isMe ? user : null);

  const fetchUserProfile = async () => {
    try {
      const data = await Api.student.getStudentProfile(id);
      setProfile(data);
    } catch (error) {
      errorNotification(error);
    }
  };
  console.log(profile);
  React.useEffect(() => {
    if (id) {
      fetchUserProfile();
    }
  }, [id]);

  if (!profile) {
    return null;
  }

  return (
    <div className="profile-root">
      <div className="profile-header">
        <div
          style={{
            width: "160px",
            height: "160px",
            borderRadius: "8px",
            overflow: "hidden",
          }}
        >
          <img src={profile?.photo} alt="Avatar" className="profile-avatar" />
        </div>
        <div className="profile-header-info">
          <h2 className="profile-header-name">
            {profile?.surname} {profile?.name} {profile?.patronymic}
          </h2>
          <div className="profile-header-text-root">
            <div className="profile-header-text-box">
              <h3 className="profile-header-text-name">Возраст:</h3>
              <h3 className="profile-header-text-desc">
                {calculateAge(profile?.born)} лет
              </h3>
            </div>
          </div>
        </div>
      </div>
      <hr className="education-hr" />
      <div className="profile-education-container">
        <div className="profile-education-container-title-block">
          <h3 className="profile-education-container-title">Место учебы</h3>
          <span className="profile-education-container-span">
            Вся информация про место учебы
          </span>
        </div>
        <div className="profile-student-container-block">
          <div className="profile-student-container-block-info">
            <h4 className="profile-education-container-subtitle">Школа</h4>
            <span className="profile-education-container-subtitle-span">
              {profile?.shortname}
            </span>
          </div>
          <div className="profile-student-container-block-info">
            <h4 className="profile-education-container-subtitle">Класс</h4>
            <span className="profile-education-container-subtitle-span">
              {profile?.pupil_class}
              {profile?.liter}
            </span>
          </div>
          {profile?.provider_title && (
            <div className="profile-student-container-block-info">
              <h4 className="profile-education-container-subtitle">
                Провайдер
              </h4>
              <span className="profile-education-container-subtitle-span">
                {profile?.provider_title}
              </span>
            </div>
          )}
        </div>
      </div>
      <hr className="education-hr" />

      <div className="experience-container">
        <h3 className="experience-title">Контакты</h3>
        <div className="contact-user-block">
          <div className="contact-user-info-block">
            <h4 className="contact-user-title">Номер ребенка</h4>
            <span className="contact-user-span">{profile?.child_phone}</span>
          </div>
          <>
            {profile?.parent_phone && (
              <div className="contact-user-info-block">
                <h4 className="contact-user-title">Номер родителя</h4>
                <span className="contact-user-span">
                  {profile?.parent_phone}
                </span>
              </div>
            )}
          </>
          <>
            {profile?.statement && (
              <div className="contact-user-info-block">
                <h4 className="contact-user-title">
                  Номер заявления на госуслугах
                </h4>
                <span className="contact-user-span">{profile?.statement}</span>
              </div>
            )}
          </>
        </div>
      </div>

      <Link to={`/student/edit/${id}`}>
        <Button variant="contained">Редактировать</Button>
      </Link>
    </div>
  );
};
