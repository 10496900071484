import React from "react";
import Input from "../../ui-component/Input/Input";
import AddScanComponent from "../AddTeacher/AddScan/AddScan";
import InputDate from "../../ui-component/InputDate/InputDate";
import Remove from "../../assets/icons/x.svg";
import "./Education.scss";

export default function Education({
  // onInputChange,
  setEducations,
  education,
  educations,
  title,
  index,
  onRemoveEducation,
}) {
  const [form, setForm] = React.useState({
    type: "среднее профессиональное образование",
    university: "",
    specialty: "",
    from: "",
    to: "",
  });

  React.useEffect(() => {
    if (education) {
      setForm({
        type: education.type ?? "",
        university: education.university ?? "",
        specialty: education.specialty ?? "",
        from: education.from ?? "",
        to: education.to ?? "",
      });
    }
  }, [education]);

  const [selectedImage, setSelectedImage] = React.useState(null);
  const [imageName, setImageName] = React.useState("");
  const fileInputRef = React.useRef(null);

  const handleInputChange = (value, index) => {
    // onInputChange(fieldName, value);
    setForm({ ...form, [index]: value });
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setSelectedImage(selectedFile);
      setImageName(selectedFile.name);
    }
  };

  function handleClick(e) {
    e.preventDefault();
  }

  return (
    <div className="educations-container-block">
      <div className="collapse__header">
        <h4 className="collapse__title">{title}</h4>
        {index !== 0 && (
          <div>
            <button
              onClick={handleClick}
              style={{
                border: "none",
                backgroundColor: "transparent",
              }}
            >
              <img
                className="images-remove"
                src={Remove}
                onClick={onRemoveEducation}
                alt="Удалить"
              />
            </button>
          </div>
        )}
      </div>
      <div className="Selected-app-block">
        <div className="Selected-app">
          <h4 className="selected__title">Вид образования</h4>
          <select
            onChange={(e) => handleInputChange(e.target.value, "type")}
            value={form.type}
            className="select-container"
            name={`education[${index}][type]`}
          >
            <option value="среднее профессиональное образование">
              среднее профессиональное образование;
            </option>
            <option value="высшее образование - бакалавриат">
              высшее образование - бакалавриат;
            </option>
            <option value="высшее образование - специалитет, магистратура">
              высшее образование - специалитет, магистратура;
            </option>
            <option value="высшее образование - аспирантура;">
              высшее образование - аспирантура;
            </option>
          </select>
        </div>

        <Input
          value={form.university}
          className={"input2"}
          name={`education[${index}][university]`}
          title="Место учебы"
          placeholder={"Название учебного заведения"}
          onChange={(e) => handleInputChange(e.target.value, "university")}
        />
      </div>
      <div>
        <Input
          value={form.specialty}
          className={"input3"}
          name={`education[${index}][specialty]`}
          title="Специальность"
          placeholder={"Введите специальность"}
          onChange={(e) => handleInputChange(e.target.value, "specialty")}
        />
      </div>
      <div className="datePickers-block">
        <InputDate
          value={form.from}
          title="Дата начала обучения"
          name={`education[${index}][from]`}
          onChange={(e) => handleInputChange(e.target.value, "from")}
        />
        <InputDate
          value={form.to}
          title="Дата окончания обучения"
          name={`education[${index}][to]`}
          onChange={(e) => handleInputChange(e.target.value, "to")}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "end",
          justifyContent: "space-between",
        }}
      >
        <div className="diplom-container">
          <AddScanComponent
            input={
              <input
                style={{ display: "none" }}
                type="file"
                name={`education[${index}][scan]`}
                id={`education[${index}][scan]`}
                onChange={handleImageChange}
              />
            }
            index={index}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            imageName={imageName}
            setImageName={setImageName}
            fileInputRef={fileInputRef}
            // value={value}
          />
        </div>
      </div>
    </div>
  );
}
