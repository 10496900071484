import React from "react";
import "./Checkbox.scss";

function CheckboxComponent({
  children,
  text,
  handleClick,
  name,
  value,
  defaultChecked,
}) {
  const [isChecked, setIsChecked] = React.useState(false);

  React.useEffect(() => {
    if (defaultChecked) {
      setIsChecked(defaultChecked);
    }
  }, [defaultChecked]);

  const handleCheckboxChange = (e) => {
    setIsChecked(!isChecked);
    handleClick && handleClick(e);
  };

  return (
    <div className="checkbox-container">
      <label>
        <input
          value={value}
          name={name}
          type="checkbox"
          checked={isChecked}
          onChange={(e) => {
            handleCheckboxChange(e);
          }}
        />
        {text}
      </label>
      {isChecked && children}
    </div>
  );
}

export default CheckboxComponent;
